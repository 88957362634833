import Glide from '@glidejs/glide'
import { initDefer, initVideoDefer } from "./modules/defer";
import { preloaderClose } from "./animations/preloaderClose";

import "../sass/style.scss";
import "./modules/smoothScroll";

window.addEventListener('load', function () {
  new Glide('.products-slide', {
    type: 'carousel',
    perView: 1,
    focusAt: 'center',
    gap: 0,
    startAt: 0,
  }).mount()

  initDefer()
  preloaderClose()
  initVideoDefer()
}); 
