import { gsap, Power3 } from "gsap";


export const preloaderClose = () => {
  let tl = gsap.timeline();


  // tl.to(".preloader-counter", {
  //   duration: .5,
  //   opacity: 0,
  //   translateY: '-50px',
  // }, 0)
  // tl.to(".preloader-logo", {
  //   duration: .5,
  //   opacity: 0,
  //   translateY: '-100px',
  // }, 0)
  tl.to(".preloader-overlay", {
    duration: 1,
    scaleY: 1,
    ease: Power3.easeOut,
    transformOrigin: "bottom"
  }, .5)
  tl.to(".preloader-background", {
    duration: 1,
    scaleY: 0,
    ease: Power3.easeOut,
    transformOrigin: "top"
  }, 1.5)
  tl.to(".preloader-overlay", {
    duration: 1,
    scaleY: 0,
    ease: Power3.easeOut,
    transformOrigin: "top"
  }, 1.8)

  tl.to(".preloader", {
    duration: 0,
    scaleY: 0,
    transformOrigin: "top"
  }, 2)

}