export const initDefer = () => {
  const imgDefer = document.querySelectorAll('.defer'),
    styleDefer = document.querySelectorAll('.defer-style');

  if (imgDefer) {
    imgDefer.forEach(img => {
      if (img.getAttribute('data-src')) {
        img.setAttribute('src', img.getAttribute('data-src'));
      }
    })
  }

  if (styleDefer) {
    styleDefer.forEach(element => {
      if (element.getAttribute('data-style')) {
        element.style.backgroundImage = "url(" + element.getAttribute('data-style') + ")";
      }
    })
  }
}

export const initVideoDefer = () => {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));
  
    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
              }
            }
  
            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });
  
      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
}